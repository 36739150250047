
const ProjectData = [
    {
        id: 1,
        title: "David on the Train",
        image: '/assets/images/david.png',
        alt: 'David on the Train',
        description: "The most beautiful man in the world and I used to ride the train together. Some fabulous poeple worked on this film.",
        embedURL: "https://www.youtube.com/embed/V7EBaqT0M9U",
    },
    {
        id: 2,
        title: "Infinite Tea Party",
        image: '/assets/images/tea.png',
        alt: "Infinite Tea Party",
        description: "This is the story of Lydia.  She tends the graveyard between lives, rousing the souls for tea, listening to the stories they share of their last lives, and sending them on to the next lives having been heard and appreciated.",
        embedURL: "https://www.youtube.com/embed/nq36K-9DhpE",
    },
    {
        id: 3,
        title: "woof",
        image: '/assets/images/woof.png',
        alt: "Infinite Tea Party Image",
        description: "I wrote this in the basement of Televisual in Greensboro NC the summer of 1997.  I submitted it to a vampire poetry and fiction journal who told me, in the best rejection letter ever, “This is too weird for us. Took me twenty years to find a way to tell this story.",
        embedURL: "https://www.youtube.com/embed/Vdbptxe9kJQ",
    },
    {
        id: 4,
        title: "Echo and Narcissus",
        image: '/assets/images/echo-and-narcissus.jpeg',
        alt: "Echo-and-Narcissus",
        description: "Echo's wings were made from the shawl Philia wore in our 2008 production of Funny Thing Happened on the Way to the Forum.",
        embedURL: "https://www.youtube.com/embed/-cQTqOdMVWE",
    },
    {
        id: 5,
        title: "Positation",
        image: '/assets/images/positation.jpeg',
        alt: "Positation",
        description: "So there was Kevin and Kyle and Eddie and Monica and string cheese and no heat and huddling under the 350 to keep warm, and we watched AI and Kyle and I could not stop laughing at how Haley Joel Osment laughed at the dinner table and we could see our breath as we worked, and as I ducked behind the curtain to wait for Kevin to take the shot I thought, “I want to do this for the rest of my life. There are some films that live because they were brilliant, and some because they were terrible, and some because of the moments in making them.  None of us had any idea what we were doing, we just did it, and it never failed to amaze me how wonderful everyone was. Even though we didn't use the soundtrack we recorded of all of us singing the song, Dan got his girlfriend to do a perfect version for us.",
        embedURL: "https://www.youtube.com/embed/tcvvtJJbtqg",
    },
    {
        id: 6,
        title: "Robot vs Verses",
        image: '/assets/images/robot.jpeg',
        alt: "Robot vs Verses",
        description: "A robot poet battles a heckler and meets the love of his life.",
        embedURL: "https://www.youtube.com/embed/cwgYLc4nKWQ",
    },
    {
        id: 7,
        title: "Six Swans",
        image: '/assets/images/six-swans.jpeg',
        alt: "Six-Swans",
        description: "A horribly flawed gem, one I rarely share, but very close to my heart.  I may try this again, or something else like it, but not for a while.  I love this film, not for what it ended up being but for what I dreamed it could be.  Everyone working on it did great work, just exactly what was needed, but the end product did not gel right.",
        embedURL: "https://www.youtube.com/embed/4flKVAEa4_c",
    },
    {
        id: 8,
        title: "Remington Alloy",
        image: '/assets/images/remington.png',
        alt: 'Remington Alloy', 
        description: "One year only one student wanted to take my class so we made this. We did more stuff too, but this was the project April was in.",
        embedURL: "https://www.youtube.com/embed/micEMyN9IqY",
    },
    {
        id: 9,
        title: "Motif",
        image: '/assets/images/motif.png',
        alt: 'Motif',
        description: "So. Much. Origami.",
        embedURL: "https://www.youtube.com/embed/fX0jpuyIM8U",
    },
    {
        id: 10,
        title: "Cotton and Caligula",
        subTitle: 'A Comedy of Cults',
        image: '/assets/images/Cotton.png',
        alt: 'Cotton and Caligula A Comedy of Cults',
        description: "Cotton Mather and Caligula start a cult. Hijinks ensue.",
        embedURL: "https://www.youtube.com/embed/fcKC6f0YvLE",
    },
]

export default ProjectData