import React from 'react'
import './About.css'

const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">THIS IS THE ABOUT PAGE</h1>
      <h4>site currently in development</h4>
    </div>
  );
}

export default About